import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import GeneralContent from "../components/generalContent"

const Post = ({ pageContext, location }) => {
  const { postContent, postTitle, postImage } = pageContext

  return (
    <Layout theme="lieblingsplatz" location={location}>
      <SEO title={`Lieblingsplatz Presse - ${postTitle}`} />
      <GeneralContent
        headlineType={1}
        title={postTitle}
        content={postContent.json}
        textCentered={false}
        image={postImage}
      />
    </Layout>
  )
}

export default Post
